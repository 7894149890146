import React, { ReactNode, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import Box, { BoxProps } from '../../components/primitives/Box'
import { responsiveBackgroundImage } from '../../styles/helpers'
import { SpaceScaleValue } from '../../styles/stylePropTypes'
import { FormSectionRef, RegisterButton } from './RegistrationFormSection'
import VideoMp4 from './bubbles-v1-2x-optimized.mp4'
import VideoWebM from './bubbles-v3-12-1.webm'
import NoiceImage from './noice.png'

export const query = graphql`
  {
    backgroundImage: file(relativePath: { eq: "images/circle/hero-bg.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

export function CircleHero(props: { formSectionRef: FormSectionRef }) {
  const data = useStaticQuery(query)
  const backgroundImage = getImage(data.backgroundImage)!
  return (
    <Box
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingTop={{ xs: 120, md: 148 }}
      paddingBottom={{ xs: 64, md: 120 }}
      paddingX={16}
      color="white"
      position="relative"
      zIndex={0}
      css={`
        ${responsiveBackgroundImage(
          backgroundImage.images.fallback?.srcSet || '',
          backgroundImage.backgroundColor || '',
        )}
      `}
    >
      <HeroVideo
        video={{ mp4: VideoMp4, webm: VideoWebM }}
        overlayImage={NoiceImage}
        backgroundImage={backgroundImage.images.fallback?.src}
      />
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems="center"
        gap={8}
      >
        <Pill color={'#C99FFF' as BoxProps['color']}>
          March 27, 2025 <Box.span marginX={2}>•</Box.span> 12:00–3:00 PM ET
        </Pill>
        <Pill color={'#53CCF1' as BoxProps['color']}>
          <Box.span
            display="inline-block"
            position="relative"
            top={-2}
            marginRight={4}
            width={8}
            height={8}
            backgroundColor={'#53CCF1' as BoxProps['color']}
            borderRadius={9999}
          />{' '}
          <Box.span>Online</Box.span>
        </Pill>
      </Box>
      <Box.h1
        font="circleH1"
        textTransform="uppercase"
        paddingTop={{ xs: 16, md: 48 }}
      >
        Circle
      </Box.h1>
      <Box
        font="medium"
        paddingTop={{ xs: 32, md: 48 }}
        maxWidth={620}
        textAlign="center"
      >
        Join us for a half-day virtual event that centers on the three pillars
        of engineering effectiveness: business outcomes, developer productivity,
        and developer experience.
      </Box>
      <Box paddingTop={32}>
        <RegisterButton
          formSectionRef={props.formSectionRef}
          withBackgroundShadow
        />
      </Box>
    </Box>
  )
}

function Pill({
  color,
  children,
}: {
  color: BoxProps['color']
  children: ReactNode
}) {
  return (
    <Box.span
      font="circlePill"
      borderRadius={9999}
      borderStyle="solid"
      borderColor={color}
      color={color}
      paddingX={{ xs: 12, md: 16 }}
      paddingTop={{ xs: 4, md: 6 as SpaceScaleValue }}
      paddingBottom={{ xs: 2, md: 4 }}
      css={`
        mix-blend-mode: difference;
      `}
    >
      {children}
    </Box.span>
  )
}

function HeroVideo({
  video: { webm, mp4 },
  overlayImage,
  backgroundImage,
}: {
  video: {
    mp4: string
    webm: string
  }
  overlayImage: string
  backgroundImage?: string
}) {
  const videoRef = useRef<HTMLVideoElement>(null)

  if (videoRef) {
    void videoRef.current?.play()
  }
  return (
    <Box
      position="absolute"
      height="100%"
      width="100%"
      top="0"
      left="0"
      zIndex={-1}
      overflow="hidden"
    >
      <video
        autoPlay
        muted
        loop
        playsInline
        preload="metadata"
        ref={videoRef}
        poster={backgroundImage}
        style={{
          height: '100%',
          width: '100%',
          objectFit: 'cover',
        }}
      >
        <source src={webm} type="video/webm" />
        <source src={mp4} type="video/mp4" />
        {backgroundImage ? (
          <img src={backgroundImage} loading="lazy" alt="" />
        ) : null}
      </video>
      <Box
        position="absolute"
        top="0"
        left="0"
        bottom="0"
        right="0"
        backgroundImage={`url(${overlayImage})`}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        opacity={0.5}
        css={`
          background-blend-mode: multiply;
          mix-blend-mode: multiply;
        `}
      />
    </Box>
  )
}
