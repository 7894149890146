import React, { FormEvent, useState } from 'react'
import Box, { BoxProps } from '../../components/primitives/Box'
import Button from '../../components/system/Button'
import Row from '../../components/primitives/Row'
import _ from 'lodash'
import { sendContactDataToHS } from '../../hubspot'
import { ContactData } from '../form/GetStartedForm'

const formId = 'd52f9018-230a-46c0-87e4-7b8383aef5e0'

export type FormSectionRef = React.RefObject<HTMLDivElement>

export function RegistrationFormSection() {
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  async function onSubmit(event: FormEvent) {
    event.preventDefault()
    setErrorMessage('')
    setIsLoading(true)
    const data: ContactData = {
      firstName,
      lastName,
      email,
    }
    try {
      const res = await sendContactDataToHS(data, formId)
      if (res.status === 200) {
        setIsSubmitted(true)
      } else {
        setErrorMessage('Oops, an error. Please try again.')
        // @ts-ignore
        Sentry.captureException(JSON.stringify(res))
      }
    } catch (error) {
      // @ts-ignore
      Sentry.captureException(error)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      gap={{ xs: 32, md: 48, lg: 120 }}
    >
      <Box flex={1}>
        <Box.h2 font="circleH2">Save your seat today</Box.h2>
        <HrLeft />
        <Box font="medium">
          What is engineering effectiveness, really? How do you set goals around
          it? And how should you go about measuring and improving the impact of
          engineering teams?
          <br />
          <br />
          Swarmia Circle invites seasoned engineering and product leaders,
          including Meri Williams, John Cutler, and Nathen Harvey, to discuss
          everything from the challenges of goal setting to scaling engineering
          culture during rapid growth.
        </Box>
      </Box>
      <Box flex={1} alignSelf={{ xs: 'none', md: 'center' }}>
        {isSubmitted ? (
          <Box font="medium">
            You’re in! 🎉 You should see a calendar invite in your inbox in a
            few minutes.
          </Box>
        ) : (
          <Box.form onSubmit={onSubmit}>
            {errorMessage ? (
              <Box font="medium" marginBottom={8} color="errorRed">
                {errorMessage}
              </Box>
            ) : null}
            <Box
              display="flex"
              flexDirection={{
                xs: 'column',
                sm: 'row',
                md: 'column',
                lg: 'row',
              }}
              gap={16}
            >
              <Input
                type="text"
                name="firstName"
                placeholder="First name"
                required
                disabled={isLoading}
                onChange={e => setFirstName(e.target.value)}
              />
              <Input
                type="text"
                name="lastName"
                placeholder="Last name"
                required
                disabled={isLoading}
                onChange={e => setLastName(e.target.value)}
              />
            </Box>
            <Input
              marginTop={16}
              type="email"
              name="email"
              placeholder="Email"
              required
              disabled={isLoading}
              onChange={e => {
                setEmail(e.target.value)
              }}
            />
            <Box.label display="flex" marginY={32}>
              <Row space={12}>
                <Box.input
                  type="checkbox"
                  name="terms"
                  required
                  disabled={isLoading}
                  width={20}
                  height={20}
                  margin={0}
                  alignSelf="flex-start"
                />
                <Box font="circleTerms" color={'#50659A' as BoxProps['color']}>
                  I agree to receive email communication in accordance with
                  Swarmia’s{' '}
                  <Button
                    variant="basicLink"
                    href="/privacy/"
                    color={'#50659A' as BoxProps['color']}
                    css={`
                      :hover {
                        color: white;
                      }
                    `}
                  >
                    Privacy Policy
                  </Button>
                </Box>
              </Row>
            </Box.label>
            <RegisterButton isSubmit disabled={isLoading} />
          </Box.form>
        )}
      </Box>
    </Box>
  )
}

export function RegisterButton(props: {
  formSectionRef?: FormSectionRef
  isSubmit?: boolean
  withBackgroundShadow?: boolean
  disabled?: boolean
}) {
  function onClick() {
    props.formSectionRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }
  return (
    <Button
      type={props.isSubmit ? 'submit' : 'button'}
      variant="primary"
      disabled={Boolean(props.disabled)}
      onClick={props.isSubmit ? undefined : onClick}
      borderRadius={9999}
      paddingX={64}
      height={56}
      css={`
        box-shadow: ${props.withBackgroundShadow
          ? '0px 30px 30px 0px rgba(15, 15, 40, 0.60);'
          : 'none'};
      `}
    >
      Register
    </Button>
  )
}

function HrLeft() {
  return (
    <Box
      height={2}
      width={130}
      marginTop={16}
      marginBottom={32}
      css={`
        background: linear-gradient(
          90deg,
          #9d93f6 0%,
          #547eed 20%,
          #1cba60 40%,
          rgba(92, 219, 241, 0) 100%
        );
      `}
    />
  )
}

function Input(props: BoxProps<'input'>) {
  return (
    <Box.input
      width="100%"
      padding={16}
      color={'#C1CCE7' as BoxProps['color']}
      backgroundColor="transparent"
      borderRadius={16}
      borderStyle="solid"
      borderColor={'#C1CCE7' as BoxProps['color']}
      {...props}
    />
  )
}
